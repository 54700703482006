declare let webpack: PlatformGlobalSettings;
declare let customRemotes: Record<string, string>;

export const init = (): void => {
  window.SA_CONSTANTS = Object.freeze({
    endpoint:
      webpack.endpoint === 'window.location.origin'
        ? window.location.origin
        : webpack.endpoint,
    graphqlGatewayEndpoint:
      webpack.graphqlGatewayEndpoint === 'window.location.origin'
        ? window.location.origin
        : webpack.graphqlGatewayEndpoint,
    env: Object.freeze({
      live: webpack.env.live,
      sentry: webpack.env.sentry,
      track: webpack.env.track,
      admin: webpack.env.admin
    })
  });

  Object.assign(window, {
    CUSTOM_REMOTES: Object.freeze({ ...customRemotes })
  });
};
